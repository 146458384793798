import tw from 'twin.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const MainStyled = styled('div')(({ isMain }) => [tw`
    relative
    w-full
    min-h-[320px]
    flex
    items-center
    justify-center
    aspect-[3/1]
    sm:aspect-[4/3]
    md:aspect-[3/1]
    bg-[#002145]
    overflow-hidden
  `, (() => isMain && tw`w-full h-[calc(100vh-38px)] sm:h-[calc(100vh-26px)] md:h-[calc(100vh-28px)]`)]);

export const MainBg = tw.img`
  w-full
  h-full
  object-cover
`;

export const FieldBg = tw.img`
  w-full
  mb-[180px]
  md:mb-[120px]
  sm:mb-[40px]
  aspect-square
  object-cover
`;

export const ContentsContainer = tw.div`
  absolute
  top-0
  left-0
  w-full
  h-full
  flex
  items-center
  justify-center
`;

export const InnerContainerStyled = tw.div`
  w-10/12
  flex
  flex-col
  items-start
  gap-[16px]
`;

export const MainScheduleContainerStyled = tw.div`
  w-full
  flex
  flex-col
  items-start
`;

export const ScheduleContainerStyled = tw.div`
  flex
  gap-[8px]
  sm:flex-col
  sm:gap-0
`;

export const TitleStyled = styled('h1')(({ field }) => [css`
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
`, tw`
    font-bold
    text-white
    text-[60px]
    sm:text-[44px]
    md:text-[48px]
    whitespace-pre-wrap
    leading-[56px]
    sm:leading-[40px]
    md:leading-[52px]
  `, (() => {
  switch (field) {
    case 'ai':
      return tw`text-[#a3ccb9]`;
    case 'bio':
      return tw`text-[#00a1e9]`;
    case 'contents':
      return tw`text-[#bd6eaa]`;
    default:
      return tw`text-blue-500`;
  }
})]);

export const ScheduleTextStyled = tw.span`
  text-white
  font-bold
  text-heading2
  whitespace-pre-wrap
  sm:text-body2
  md:text-body2
`;

export const SponsorContainerStyled = tw.div`
  flex
  items-center
  gap-[12px]
`;

export const SponsorTextStyled = tw.span`
  text-heading3
  sm:text-body1
  md:text-body1
  text-red-500
  font-bold
  whitespace-pre-wrap
`;

export const LogoImageStyled = tw.img`
  h-[40px]
  md:h-[30px]
  sm:h-[30px]
  object-cover
`;
