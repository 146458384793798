import React from 'react';
import Map from '../Map';
import { InnerContainerStyled, NavigationStyled, TitleContainerStyled } from './NavigationStyled';
import { TitleText } from '../commonStyled';

export default function Navigation({ language }) {
  return (
    <NavigationStyled>
      <InnerContainerStyled>
        <TitleText className={'!text-white'}>{language === 'en' ? 'LOCATION' : '오시는 길'}</TitleText>
        {/*<Map />*/}
        <TitleContainerStyled>
          <span
            className={`text-white text-body2 whitespace-pre-wrap font-bold`}>{`버스: 교총회관/ 서울시 품질시험소 한국교원단체 총연합회에서 하차하시면 됩니다.\n지하철: 양재역/ 선바위역에서하차`}</span>
          <div className={`w-full flex flex-col items-start gap-[6px]`}>
            <span className={`text-[#a3ccb9] text-body2 font-bold`}>* 대중교통 이용 시 상세내역 전달드립니다.</span>
            <span className={`text-white text-body2 font-bold`}>1. 3호선: 신분당선양재역10번출구</span>
            <ul className={`list-disc pl-[24px]`}>
              <li className={`text-white text-body2 font-bold`}>버스: 서초 18번, 18-1번(한국교총, 품질시험 연구소 하차)</li>
              <li className={`text-white text-body2 font-bold`}>버스 중앙 차로: 541번, 542번(한국교총, 품질시험 연구소 하차)</li>
            </ul>
            <span className={`text-white text-body2 font-bold`}>2. 4호선: 선바위역 1번 출구</span>
            <ul className={`list-disc pl-[24px]`}>
              <li className={`text-white text-body2 font-bold`}>버스: 서초 18번, 542번(한국교총, 품질시험 연구소 하차)</li>
            </ul>
            <span className={`text-white text-body2 font-bold`}>3. 신분당선 양재시민의 숲(매헌) 역 5번 출구</span>
            <ul className={`list-disc pl-[24px]`}>
              <li className={`text-white text-body2 font-bold`}>도보로 약 15분 소요</li>
            </ul>
          </div>
        </TitleContainerStyled>
      </InnerContainerStyled>
    </NavigationStyled>
  );
}