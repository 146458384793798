import tw from 'twin.macro';
import styled from '@emotion/styled';

export const OverviewStyled = styled('article')(({field}) => [tw`
  w-full
  py-[40px]
  flex
  items-center
  justify-center
`,(() => {
  switch (field){
    case 'ai':
      return tw`bg-[#0B3740]`
    case 'bio':
      return tw`bg-[#00437c]`
    case 'contents':
      return tw`bg-[#331544]`
    default:
      return tw`bg-[#002145]`
  }
})]);

export const ScheduleGraphStyled = tw.div`
  w-full
  flex
  flex-col
  items-start
`;
export const RowStyled = tw.div`
  w-full
  flex
  items-stretch
  text-white
  [:not(:last-child)]:border-b
  [:not(:last-child)]:border-solid
  [:not(:last-child)]:border-white
`;

export const TimeBoxStyled = tw.div`
  w-1/3
  min-w-[33.333333%]
  py-[16px]
  flex
  items-center
  justify-center
  text-body1
  sm:text-caption2
  md:text-caption1
  font-bold
  leading-[16px]
`;

export const ProgramBoxStyled = tw.div`
  w-full
  min-h-[84px]
  px-[12px]
  py-[20px]
  flex
  items-center
  justify-center
  text-body1
  sm:text-caption2
  md:text-caption1
  leading-[16px]
  border-l
  border-solid
  border-white
`;

export const ProgramTextStyled = tw.div`
  w-full
  flex
  flex-col
  items-start
  gap-[8px]
  leading-[20px]
  text-body1
  sm:text-caption2
  md:text-caption1
  leading-[16px]
  font-bold
  whitespace-pre-wrap
`;