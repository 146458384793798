import React from 'react';
import {
  Row3ItemStyled,
  RowItemStyled, RowItemTextStyled,
  TableHead3ItemStyled,
  TableHeadItemStyled,
  TableHeadStyled,
  TableHeadTextStyled,
} from './AiStyled';
import { ProgramBoxStyled, ProgramTextStyled, RowStyled, TimeBoxStyled } from '../OverviewStyled';

export default function Ai({ language }) {
  return <React.Fragment>
    <TableHeadStyled>
      <TableHeadItemStyled>
        <TableHeadTextStyled>{language === 'en' ? 'Program' : '프로그램'}</TableHeadTextStyled>
      </TableHeadItemStyled>
      <TableHeadItemStyled>
        <TableHeadTextStyled>{language === 'en' ? 'Time(Seoul)' : '시간(서울)'}</TableHeadTextStyled>
      </TableHeadItemStyled>
      <TableHeadItemStyled>
        <TableHeadTextStyled>{language === 'en' ? 'Time(Riyadh)' : '시간(리야드)'}</TableHeadTextStyled>
      </TableHeadItemStyled>
      <TableHead3ItemStyled>
        <TableHeadTextStyled>{language === 'en' ? 'Detail' : '상세내용'}</TableHeadTextStyled>
      </TableHead3ItemStyled>
    </TableHeadStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'Registration' : '등록'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>14:00-14:30 (’30)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>-</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'Registration' : '등록'}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'Introduction' : '개막식'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>14:30-14:40 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>08:30-08:40 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? `Invest Seoul Introduction\nBonhi Gu, Commissioner` : `인베스트서울 소개\n구본희 대표`}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'Introduction' : '개막식'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>14:40-14:50 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>08:40-08:50 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? `Seoul AI Hub Introduction\nChanjin Park, Chief of Seoul AI Hub` : '서울 AI 허브 소개\n박찬진 센터장'}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'Seminar' : '세미나'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>14:50-15:30 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>08:50-09:30 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? `2024 Trend Lecture\n"Entering Middle East Market Needs To Be Differentiated by Each Countries"\nHyun-Woong Koo, Co Founders of Next Wave Venture Partners` : `2024 트렌드 강연\n"중동 진출 전략도 국가별 차별화가 필요하다"\n구현웅 넥스트웨이브 벤처파트너스 대표`}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'IR Pitching' : 'IR 피칭'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>15:30-16:10 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>09:30-10:10 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'IR Pitching Session (4 Companies)' : 'IR 피칭 세션 (4개사)'}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? '1:1 Meeting and Networking' : '1:1 미팅 및 네트워킹'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>16:10-16:50 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>10:10-10:50 (’40)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? `1:1 IR Companies Meeting and\nNetworking` : 'IR 기업 1:1 미팅 및 네트워킹'}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>

    <RowStyled>
      <RowItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'End Of Event' : '행사종료'}</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>16:50-17:00 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <RowItemStyled>
        <RowItemTextStyled>10:50-11:00 (’10)</RowItemTextStyled>
      </RowItemStyled>
      <Row3ItemStyled>
        <RowItemTextStyled>{language === 'en' ? 'End Of Event' : '행사종료'}</RowItemTextStyled>
      </Row3ItemStyled>
    </RowStyled>
  </React.Fragment>;
}