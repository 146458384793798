import tw from 'twin.macro';

export const AgreeStyled = tw.div`
  w-1/2
  py-[24px]
  px-[20px]
  flex
  flex-col
  items-start
  gap-[24px]
  rounded-[12px]
  bg-white
`
export const TitleTextStyled = tw.h1`
  text-body1
  font-bold
`