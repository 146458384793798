import tw from 'twin.macro';
import styled from '@emotion/styled';

export const TabStyled = styled('div')(({field}) => [tw`
  w-full
  flex
  items-center
  justify-center
`,(() => {
  switch (field){
    case 'ai':
      return tw`bg-[#0b3740]`
    case 'bio':
      return tw`bg-[#00437c]`
    case 'contents':
      return tw`bg-[#331544]`
    default:
      return tw`bg-[#002145]`
  }
})])

export const InnerContainerStyled = tw.div`
  w-10/12
  flex
  gap-[8px]
`

export const TabButtonStyled = styled('button')(({isSelected, field}) => [tw`
  w-full
  py-[8px]
  flex
  items-center
  justify-center
  text-[18px]
  leading-[22px]
  sm:text-[14px]
  sm:py-[4px]
  sm:leading-[18px]
  md:text-[16px]
  md:py-[4px]
  md:leading-[20px]
  text-white
  font-bold
`,(() => {
  if(isSelected)
  switch (field){
    case 'ai':
      return tw`text-[#a3ccb9]`
    case 'bio':
      return tw`text-[#00a1e9]`
    case 'contents':
      return tw`text-[#bd6eaa]`
    default:
      return tw`text-blue-500`
  }
})])