import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InnerContainer, Input, TitleText } from '../commonStyled';
import {
  AgreeContainerStyled,
  CheckboxStyled,
  CompanyContainerStyled,
  DimmedStyled,
  GridContainer,
  InformationContainerStyled,
  InputTitleStyled,
  RegistrationBoxStyled,
  RegistrationStyled,
  RegistrationTitleTextStyled,
  ShowDetailButton,
  SubmitButton,
} from './RegistrationStyled';
import Agree from './Agree';

export default function Registration({ language }) {
  const itemsRef = useRef(new Map());
  const [agree, setAgree] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState({});
  const [companyList, setCompanyList] = useState([]);
  // const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    (async () => {
      const { success, result } = await fetch(`https://investtrend2024.com:3500/api/company`).then(res => res.json());
      if (success) {
        setCompanyList(result);
      }
    })();
  }, []);

  const handleSetRegistrationInfo = useCallback((key, value) => {
    switch (key) {
      case 'businessType':
      case 'attend':
      case 'companiesList':
        if (info[key]) {
          if (info[key].includes(value)) {
            setInfo(prevState => ({
              ...prevState, [key]: prevState[key].filter(x => x !== value),
            }));
          } else {
            setInfo(prevState => ({
              ...prevState, [key]: [...prevState[key], value],
            }));
          }
        } else {
          setInfo(prevState => ({
            ...prevState, [key]: [value],
          }));
        }
        break;
      default:
        setInfo(prevState => ({ ...prevState, [key]: value }));
    }
  }, [info]);

  const validateInfo = useCallback(async () => {
    return new Promise((resolve, reject) => {
      ['companyName', 'participantName', 'jobTitle', 'email', 'phoneNumber'].forEach((key) => {
        if (!info[key] || (info[key] && info[key].length === 0)) {
          throw new Error(key);
        }
      });
      resolve(true);
    });
  }, [info]);

  const registrationInfo = useCallback(async () => {
    validateInfo()
      .then(async (v) => {
        if (v && agree) {
          await fetch('https://investtrend2024.com:3500/api/info', {
            method: 'POST', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify({
              info: Object.entries(info).reduce((pre, [key, value]) => {
                if (value && value.length > 0) {
                  switch (key) {
                    case 'businessType':
                    case 'attend':
                    case 'companiesList':
                      pre[key] = value.join();
                      break;
                    default:
                      pre[key] = value;
                  }
                }
                return pre;
              }, { category: 'ai' }),
            }),
          }).then((res) => res.json())
            .then((json) => {
              if (json.success) {
                setInfo({});
                window.alert(`Thank you,\nYour registration has been confirmed`);
                window.scrollTo(0, 0);
              }
            });
        }
      })
      .catch((e) => {
        const item = itemsRef.current.get(e.message);
        item?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
  }, [info, agree]);


  return <React.Fragment>
    <RegistrationStyled>
      <InnerContainer>
        <TitleText field={'ai'}>REGISTRATION</TitleText>
        <RegistrationBoxStyled>
          <InputTitleStyled>{language === 'en' ? '1. Please Enter the Participant Information' : '1. 참석자 정보를 기입해주세요'}</InputTitleStyled>
          <InformationContainerStyled ref={(el) => {
            if (el) {
              itemsRef.current.set('companyName', el);
            }
          }}>
            <RegistrationTitleTextStyled>{language === 'en' ? 'Company Name' : '기업명'}<b
              className={`text-red-600`}>*</b></RegistrationTitleTextStyled>
            <Input value={info['companyName'] ?? ''}
                   onChange={(e) => {
                     const { value } = e.target;
                     handleSetRegistrationInfo('companyName', value);
                   }} />
          </InformationContainerStyled>

          <InformationContainerStyled ref={(el) => {
            if (el) {
              itemsRef.current.set('participantName', el);
            }
          }}>
            <RegistrationTitleTextStyled>{language === 'en' ? 'Name of Participant' : '담당자'}<b
              className={`text-red-600`}>*</b></RegistrationTitleTextStyled>
            <Input value={info['participantName'] ?? ''}
                   onChange={(e) => {
                     const { value } = e.target;
                     handleSetRegistrationInfo('participantName', value);
                   }} />
          </InformationContainerStyled>

          <InformationContainerStyled ref={(el) => {
            if (el) {
              itemsRef.current.set('jobTitle', el);
            }
          }}>
            <RegistrationTitleTextStyled>{language === 'en' ? 'Job Title' : '직함'}<b
              className={`text-red-600`}>*</b></RegistrationTitleTextStyled>
            <Input value={info['jobTitle']}
                   onChange={(e) => {
                     const { value } = e.target;
                     handleSetRegistrationInfo('jobTitle', value);
                   }} />
          </InformationContainerStyled>

          <InformationContainerStyled ref={(el) => {
            if (el) {
              itemsRef.current.set('email', el);
            }
          }}>
            <RegistrationTitleTextStyled>{language === 'en' ? 'Email' : '이메일'}<b
              className={`text-red-600`}>*</b></RegistrationTitleTextStyled>
            <Input value={info['email']}
                   onChange={(e) => {
                     const { value } = e.target;
                     handleSetRegistrationInfo('email', value);
                   }} />
          </InformationContainerStyled>

          <InformationContainerStyled ref={(el) => {
            if (el) {
              itemsRef.current.set('phoneNumber', el);
            }
          }}>
            <RegistrationTitleTextStyled>{language === 'en' ? 'Phone Number' : '전화번호'}<b
              className={`text-red-600`}>*</b>
            </RegistrationTitleTextStyled>
            <Input value={info['phoneNumber']}
                   onChange={(e) => {
                     const { value } = e.target;
                     handleSetRegistrationInfo('phoneNumber', value);
                   }} />
          </InformationContainerStyled>
        </RegistrationBoxStyled>

        <RegistrationBoxStyled>
          <InputTitleStyled>{language === 'en' ? '2. Please Select Your Primary Business Type' : '2. 귀사의 사업분야를 선택해주세요'}</InputTitleStyled>
          <GridContainer>
            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Investor (Agent, VC, PE)')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Investor (Agent, VC, PE)')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Investor(Agent, VC, PE)' : '투자자 (에이전트, 벤처캐피탈, 펀드)'}</RegistrationTitleTextStyled>
            </div>

            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Entrepreneur')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Entrepreneur')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Entrepreneur' : '스타트업'}</RegistrationTitleTextStyled>
            </div>

            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Limited Partner')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Limited Partner')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Limited Partner' : '기업'}</RegistrationTitleTextStyled>
            </div>

            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Financial Institutes/ IB')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Financial Institutes/ IB')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Financial Institutes/ IB' : '금융기관'}</RegistrationTitleTextStyled>
            </div>

            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Government')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Government')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Government' : '정부'}</RegistrationTitleTextStyled>
            </div>

            <div className={`flex items-center gap-[8px] cursor-pointer`}
                 onClick={() => handleSetRegistrationInfo('businessType', 'Others')}>
              <CheckboxStyled type='checkbox'
                              checked={info['businessType']?.includes('Others')} />
              <RegistrationTitleTextStyled>{language === 'en' ? 'Others' : '기타'}</RegistrationTitleTextStyled>
            </div>
          </GridContainer>
        </RegistrationBoxStyled>


        {/*<RegistrationBoxStyled>*/}
        {/*  <InputTitleStyled>3. Please Select 1 or more Seminar You Would Like to Attend*/}
        {/*    <br /><span className={`text-body2 text-gray-700 sm:text-caption1 md:text-caption1`}>*Please note that you can choose more than one seminar</span>*/}
        {/*  </InputTitleStyled>*/}
        {/*  <GridContainer ref={(el) => {*/}
        {/*    if (el) itemsRef.current.set('attend', el);*/}
        {/*  }}>*/}
        {/*    <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
        {/*         onClick={() => handleSetRegistrationInfo('attend', 'SaaS')}>*/}
        {/*      <CheckboxStyled type='checkbox'*/}
        {/*                      checked={info['attend']?.includes('SaaS')} />*/}
        {/*      <RegistrationTitleTextStyled>SaaS</RegistrationTitleTextStyled>*/}
        {/*    </div>*/}
        {/*    <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
        {/*         onClick={() => handleSetRegistrationInfo('attend', 'AI')}>*/}
        {/*      <CheckboxStyled type='checkbox'*/}
        {/*                      checked={info['attend']?.includes('AI')} />*/}
        {/*      <RegistrationTitleTextStyled>AI</RegistrationTitleTextStyled>*/}
        {/*    </div>*/}
        {/*    <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
        {/*         onClick={() => handleSetRegistrationInfo('attend', 'BIO')}>*/}
        {/*      <CheckboxStyled type='checkbox'*/}
        {/*                      checked={info['attend']?.includes('BIO')} />*/}
        {/*      <RegistrationTitleTextStyled>BIO</RegistrationTitleTextStyled>*/}
        {/*    </div>*/}
        {/*    <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
        {/*         onClick={() => handleSetRegistrationInfo('attend', 'Contents')}>*/}
        {/*      <CheckboxStyled type='checkbox'*/}
        {/*                      checked={info['attend']?.includes('Contents')} />*/}
        {/*      <RegistrationTitleTextStyled>Contents</RegistrationTitleTextStyled>*/}
        {/*    </div>*/}
        {/*  </GridContainer>*/}
        {/*</RegistrationBoxStyled>*/}


        <RegistrationBoxStyled>
          <InputTitleStyled>{language === 'en' ? '3. If you are an overseas VC, please select 2 or more Korean Companies you would like to meet\n*Please note, this applies to overseas VC only' : `3. 해외 VC일 경우 희망 미팅 기업을 선택해주세요 (2기업 이상)\n*해외VC에게만 해당되는 항목입니다.`}</InputTitleStyled>
          <GridContainer gap={24}
                         ref={(el) => {
                           if (el) itemsRef.current.set('companiesList', el);
                         }}>
            <div className={`flex flex-col items-start gap-[4px]`}>
              <RegistrationTitleTextStyled>[ AI ] Monday, August 26th, 2024</RegistrationTitleTextStyled>
              {companyList.filter(x => x.category === 'ai').map(x => {
                return (<CompanyContainerStyled key={x['companyId']}>
                  <div className={`flex items-center gap-[8px] cursor-pointer`}
                       onClick={() => handleSetRegistrationInfo('companiesList', x['companyId'])}>
                    <CheckboxStyled type='checkbox'
                                    checked={info['companiesList']?.includes(x['companyId'])} />
                    <RegistrationTitleTextStyled>{`${x['ordering']}. ${language === 'en' ? x['companyName'] : x['companyNameKr'] ?? x['companyName']}`}</RegistrationTitleTextStyled>
                  </div>
                  <ShowDetailButton field={'ai'}
                                    onClick={() => {
                                      if (x['deckUrl']) {
                                        window.open(x['deckUrl']);
                                      }
                                    }}>Teaser
                    Deck</ShowDetailButton>
                </CompanyContainerStyled>);
              })}
            </div>

            {/*<div className={`flex flex-col items-start gap-[4px]`}>*/}
            {/*  <RegistrationTitleTextStyled>[ AI ] Tuesday, July 2nd, 2024</RegistrationTitleTextStyled>*/}
            {/*  {companyList.filter(x => x.category === 'ai').map(x => {*/}
            {/*    return (<CompanyContainerStyled key={x['companyId']}>*/}
            {/*      <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
            {/*           onClick={() => handleSetRegistrationInfo('companiesList', x['companyId'])}>*/}
            {/*        <CheckboxStyled type='checkbox'*/}
            {/*                        checked={info['companiesList']?.includes(x['companyId'])} />*/}
            {/*        <RegistrationTitleTextStyled>{`${x['ordering']}. ${language === 'en' ? x['companyName'] : x['companyNameKr'] ?? x['companyName']}`}</RegistrationTitleTextStyled>*/}
            {/*      </div>*/}
            {/*      <ShowDetailButton field={searchParams.get('field')}*/}
            {/*                        onClick={() => {*/}
            {/*                          if (x['deckUrl']) {*/}
            {/*                            window.open(x['deckUrl']);*/}
            {/*                          }*/}
            {/*                        }}>Teaser*/}
            {/*        Deck</ShowDetailButton>*/}
            {/*    </CompanyContainerStyled>);*/}
            {/*  })}*/}
            {/*</div>*/}

            {/*<div className={`flex flex-col items-start gap-[4px]`}>*/}
            {/*  <RegistrationTitleTextStyled>[ BIO ] Monday, October 7th , 2024</RegistrationTitleTextStyled>*/}
            {/*  {companyList.filter(x => x.category === 'bio').map(x => {*/}
            {/*    return (<CompanyContainerStyled key={x['companyId']}>*/}
            {/*      <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
            {/*           onClick={() => handleSetRegistrationInfo('companiesList', x['companyId'])}>*/}
            {/*        <CheckboxStyled type='checkbox'*/}
            {/*                        checked={info['companiesList']?.includes(x['companyId'])} />*/}
            {/*        <RegistrationTitleTextStyled>{`${x['ordering']}. ${language === 'en' ? x['companyName'] : x['companyNameKr'] ?? x['companyName']}`}</RegistrationTitleTextStyled>*/}
            {/*      </div>*/}
            {/*      <ShowDetailButton field={searchParams.get('field')}*/}
            {/*                        onClick={() => {*/}
            {/*                          if (x['deckUrl']) {*/}
            {/*                            window.open(x['deckUrl']);*/}
            {/*                          }*/}
            {/*                        }}>Teaser*/}
            {/*        Deck</ShowDetailButton>*/}
            {/*    </CompanyContainerStyled>);*/}
            {/*  })}*/}
            {/*</div>*/}

            {/*<div className={`flex flex-col items-start gap-[4px]`}>*/}
            {/*  <RegistrationTitleTextStyled>[ Contents ]</RegistrationTitleTextStyled>*/}
            {/*  {companyList.filter(x => x.category === 'contents').map(x => {*/}
            {/*    return (<CompanyContainerStyled key={x['companyId']}>*/}
            {/*      <div className={`flex items-center gap-[8px] cursor-pointer`}*/}
            {/*           onClick={() => handleSetRegistrationInfo('companiesList', x['companyId'])}>*/}
            {/*        <CheckboxStyled type='checkbox'*/}
            {/*                        checked={info['companiesList']?.includes(x['companyId'])} />*/}
            {/*        <RegistrationTitleTextStyled>{`${x['ordering']}. ${language === 'en' ? x['companyName'] : x['companyNameKr'] ?? x['companyName']}`}</RegistrationTitleTextStyled>*/}
            {/*      </div>*/}
            {/*      <ShowDetailButton field={searchParams.get('field')}*/}
            {/*                        onClick={() => {*/}
            {/*                          if (x['deckUrl']) {*/}
            {/*                            window.open(x['deckUrl']);*/}
            {/*                          }*/}
            {/*                        }}>Teaser*/}
            {/*        Deck</ShowDetailButton>*/}
            {/*    </CompanyContainerStyled>);*/}
            {/*  })}*/}
            {/*</div>*/}
          </GridContainer>
        </RegistrationBoxStyled>
        <AgreeContainerStyled>
          <div className={`flex items-center gap-[8px] cursor-pointer`}
               onClick={() => setAgree(prevState => !prevState)}>
            <CheckboxStyled type='checkbox' checked={agree} />
            <RegistrationTitleTextStyled>
              {language === 'en' ? 'Yes, I agree to the collection and use of my information as described in the Data Policy (Required)' : '개인정보수집 동의 및 정보 3자 제공동의 (필수)'}
            </RegistrationTitleTextStyled>
          </div>
          <span
            onClick={() => setShowModal(true)}
            className={`text-gray-400 font-bold text-body2 cursor-pointer`}>{language === 'en' ? '[ More View ]' : '[ 더보기 ]'}</span>
        </AgreeContainerStyled>
        <SubmitButton field={'ai'}
                      onClick={async () => {
                        // window.alert(`신청 등록이 마감되었습니다.\nRegistration is now closed.`);
                        await registrationInfo();
                      }}>Submit</SubmitButton>
      </InnerContainer>
    </RegistrationStyled>
    {showModal && <DimmedStyled onClick={() => setShowModal(false)}>
      <Agree language={language} />
    </DimmedStyled>}
  </React.Fragment>;
}