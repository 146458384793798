import React, { useCallback, useEffect } from 'react';
import {
  FieldBg,
  MainStyled,
  ContentsContainer,
  InnerContainerStyled,
  TitleStyled,
  ScheduleContainerStyled,
  ScheduleTextStyled,
  SponsorContainerStyled,
  LogoImageStyled,
  SponsorTextStyled,
  MainBg,
  MainScheduleContainerStyled,
} from './MainStyled';
import { useSearchParams } from 'react-router-dom';

export default function Main({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const renderTypeTitle = useCallback(() => {
    const filed = searchParams.get('field');
    // switch (filed) {
    //   case 'ai':
    //     return 'AI';
    //   case 'bio':
    //     return 'BIO';
    //   case 'contents':
    //     return 'CONTENTS';
    //   case 'saas':
    //     return 'SaaS';
    //   default:
    //     return '';
    // }
    return 'AI';
  }, [searchParams]);

  return (<MainStyled>
    {searchParams.size === 0 && <FieldBg src={'ai_bg.png'} />}
    {/*{searchParams.size === 0 && <MainBg src={'invest_trend_bg.png'} />}*/}
    {/*{searchParams.get('field') === 'saas' && <FieldBg src={'saas_bg.png'} />}*/}
    {/*{searchParams.get('field') === 'ai' && <FieldBg src={'ai_bg.png'} />}*/}
    {/*{searchParams.get('field') === 'bio' && <FieldBg src={'bio_bg.png'} />}*/}
    {/*{searchParams.get('field') === 'contents' && <FieldBg src={'content_bg.png'} />}*/}
    <ContentsContainer>
      <InnerContainerStyled>
        <div className={`flex flex-col items-start`}>
          <TitleStyled field={'ai'}>{renderTypeTitle()}</TitleStyled>
          <TitleStyled className={`!text-white`}>{`TREND & INVESTMENT\nSEMINAR 2024`}</TitleStyled>
        </div>
        <ScheduleTextStyled>{`Date & Time: Monday, August 26th, 14:30 ~ 17:00\nLocation: Seoul AI Hub, 4th Floor`}</ScheduleTextStyled>
        {/*{searchParams.size > 0 &&*/}
        {/*  <React.Fragment>*/}
        {/*    <ScheduleContainerStyled>*/}
        {/*      {searchParams.get('field') === 'saas' &&*/}
        {/*        <ScheduleTextStyled>{`1st Seminar (SaaS): Thursday, June 27th 2024, 14:00-17:15\nHotel Peyto, Peyto Hall 2nd Floor`}</ScheduleTextStyled>}*/}
        {/*      {searchParams.get('field') === 'ai' &&*/}
        {/*        <ScheduleTextStyled>2nd Seminar (AI): Tuesday, July 2nd , 2024</ScheduleTextStyled>}*/}
        {/*      {searchParams.get('field') === 'bio' &&*/}
        {/*        <ScheduleTextStyled>3rd Seminar (BIO): Monday, October 7th , 2024,</ScheduleTextStyled>}*/}
        {/*      {searchParams.get('field') === 'contents' &&*/}
        {/*        <ScheduleTextStyled>4th Seminar (Contents): TBA</ScheduleTextStyled>}*/}
        {/*    </ScheduleContainerStyled>*/}
        {/*    <div className={`flex items-center gap-[20px] sm:flex-col sm:items-start md:flex-col md:items-start`}>*/}
        {/*      <SponsorContainerStyled>*/}
        {/*        <SponsorTextStyled>Co-Host</SponsorTextStyled>*/}
        {/*        <div className={`flex gap-[8px]`}>*/}
        {/*          <LogoImageStyled src={'invest_seoul_white_logo.png'} />*/}
        {/*          {searchParams.get('field') === 'saas' &&*/}
        {/*            <LogoImageStyled style={{ height: '55px' }} src={'kosa_white_logo.png'} />}*/}
        {/*          {searchParams.get('field') === 'ai' && <LogoImageStyled src={'seoul_ai_hub_white_logo.png'} />}*/}
        {/*          {searchParams.get('field') === 'bio' && <LogoImageStyled src={'korea_bio_white_logo.png'} />}*/}
        {/*          {searchParams.get('field') === 'contents' &&*/}
        {/*            <LogoImageStyled src={'korea_contents_agency_white_logo.png'} />}*/}
        {/*        </div>*/}
        {/*      </SponsorContainerStyled>*/}
        {/*    </div>*/}
        {/*  </React.Fragment>}*/}
        <div className={`flex items-center gap-[20px] sm:flex-col sm:items-start md:flex-col md:items-start`}>
          <SponsorContainerStyled>
            <SponsorTextStyled>Co-Host</SponsorTextStyled>
            <div className={`flex items-center gap-[8px]`}>
              <LogoImageStyled src={'invest_seoul_white_logo.png'} />
              <img className={`mt-[20px] w-[130px]`} src={'seoul_ai_hub_white_logo.png'} alt={'seoul_ai_hub_logo'} />
            </div>
          </SponsorContainerStyled>
        </div>
      </InnerContainerStyled>
    </ContentsContainer>
    {children}
  </MainStyled>);
}
