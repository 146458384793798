import tw from 'twin.macro';
import styled from '@emotion/styled';

export const TitleText = styled('hi')(({ field }) => [tw`
  text-heading1
  font-bold
  sm:text-body1
  md:text-heading3
`, (() => {
  switch (field){
    case 'ai':
      return tw`text-[#0B3740]`
    case 'bio':
      return tw`text-[#00437c]`
    case 'contents':
      return tw`text-[#331544]`
    default:
      return tw`text-indigo-900`
  }
})]);

export const InnerContainer = tw.div`
  w-10/12
  flex
  flex-col
  items-start
  justify-start
  gap-[24px]
  sm:gap-[16px]
  md:gap-[16px]
`;

export const Input = tw.input`
  w-full
  px-[8px]
  py-[4px]
  border
  border-solid
  border-gray-300
  rounded-[8px]
  outline-none
`;

export const LanguageText = styled.h1(`
  text-heading1
  font-bold
  sm:text-body2
  md:text-body1
  text-white!
`)