import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Ai from './Ai';
import SaaS from './SaaS';

export default function Invitation({ language }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const renderInvitation = useCallback((type) => {
    // switch (type){
    //   case 'saas':
    //     return <SaaS language={language}/>
    //   default:
    //     return <Ai language={language}/>
    // }
    return <Ai language={language}/>
  },[language])

  // if (searchParams.size === 0) {
  //   return <React.Fragment />;
  // } else {
  //   return renderInvitation(searchParams.get('field'))
  // }
  return <Ai language={language}/>
}