import React from 'react';
import { AgreeStyled, TitleTextStyled } from './AgreeStyled';

export default function Agree({ language }) {
  return (<AgreeStyled>
    <div className={`flex flex-col items-center gap-[8px]`}>
      <TitleTextStyled>{language === 'en' ? 'Data Policy (Agreement to Collect, Use, and Provide Personal Information)' : '개인정보 수집‧이용 및 제공 동의서'}</TitleTextStyled>
      <span
        className={`text-caption1 text-gray-400 leading-[16px]`}>{language === 'en' ? 'Invest Seoul would like to collect, use, and provide your personal information as described below for participation in the 2024 Investment Trends Seminar. \n' + 'Please read the details thoroughly and check the boxes: ' : '2024 투자트렌드 세미나 참석과 관련해 인베스트서울에서 아래와 같이 개인정보를 수집‧이용 및 제공하고자 합니다. 내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.'}</span>
    </div>
    <div className={`flex flex-col items-start gap-[12px]`}>
      <TitleTextStyled>{language === 'en' ? '□ Personal Information Collection and Use' : '□ 개인정보 수집‧이용 내역(필수사항)'}</TitleTextStyled>
      <div className={`w-full flex flex-col`}>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Item' : '항목'}</span>
          <span
            className={`text-label1`}>{language === 'en' ? 'Name, Affiliation, Title, Contact Information' : '성명, 소속, 직함, 연락처'}</span>
        </div>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Purpose' : '목적'}</span>
          <span
            className={`text-label1`}>{language === 'en' ? 'Event Notification and Information Provision' : '행사 안내 및 정보 제공'}</span>
        </div>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Retention Period' : '보유기간'}</span>
          <span className={`text-label1`}>{language === 'en' ? '2 Year' : '2 년'}</span>
        </div>
      </div>
      <span
        className={`text-caption1 text-gray-400`}>{language === 'en' ? '※ You have the right to refuse consent to the collection and use of your personal information. However, if refused, you will not be able to participate in the event.' : '※ 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 행사에 참가할 수 없습니다.'}</span>
    </div>
    <div className={`flex flex-col items-start gap-[12px]`}>
      <TitleTextStyled>{language === 'en' ? '□ Provision of Personal Information to Third Parties' : '□ 개인정보 3자 제공 내역'}</TitleTextStyled>
      <div className={`w-full flex flex-col`}>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Recipient' : '제공 받는자'}</span>
          <span
            className={`text-label1`}>{language === 'en' ? 'Bridging Group Korea, Korea Software Industry Association' : '브릿징그룹코리아, 한국소프트웨어산업협회'}</span>
        </div>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Purpose' : '제공 목적'}</span>
          <span
            className={`text-label1`}>{language === 'en' ? 'Event Notification Information Provision' : '행사 안내 및 정보 제공'}</span>
        </div>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Item' : '제공 항목'}</span>
          <span
            className={`text-label1`}>{language === 'en' ? 'Name, Affiliation, Title, Contact Information' : '성명, 소속, 직함, 연락처'}</span>
        </div>
        <div className={`flex items-center gap-[8px]`}>
          <span className={`text-label1 font-bold`}>{language === 'en' ? 'Retention Period' : '보유기간'}</span>
          <span className={`text-label1`}>{language === 'en' ? '1 Year' : '1 년'}</span>
        </div>
      </div>
      <span
        className={`text-caption1 text-gray-400`}>{language === 'en' ? '※ You have the right to refuse consent to the collection and use of your personal information. However, if refused, you will not be able to participate in the event.' : '※ 위의 개인정보 수집‧이용에 대한 동의를 거부할 권리가 있습니다. 그러나 동의를 거부할 경우 행사에 참가할 수 없습니다.'}</span>
    </div>
  </AgreeStyled>);
}