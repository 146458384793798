import React from 'react';
import { LogoImageStyled, SponsorTextStyled } from '../Main/MainStyled';
import { FooterStyled, InnerContainerStyled, TextStyled } from './FooterStyled';
import { useSearchParams } from 'react-router-dom';

export default function Footer() {
  const [searchParams, setSearchParams] = useSearchParams();

  return <FooterStyled field={'ai'}>
    <InnerContainerStyled>
      <div className={`flex flex-col items-center gap-[24px] sm:items-start md:items-start`}>
        <div className={`flex items-center gap-[12px]`}>
          <SponsorTextStyled>Co-Host</SponsorTextStyled>
          <LogoImageStyled src={'invest_seoul_white_logo.png'} />
          <img className={`w-[100px]`} src={'seoul_ai_hub_white_logo.png'} alt={'seoul_ai_hub_logo'} />
          {/*{searchParams.get('field') === 'saas' &&*/}
          {/*  <LogoImageStyled style={{ height: '55px' }} src={'kosa_white_logo.png'} />}*/}
          {/*{searchParams.get('field') === 'ai' && <LogoImageStyled src={'seoul_ai_hub_white_logo.png'} />}*/}
          {/*{searchParams.get('field') === 'contents' &&*/}
          {/*  <LogoImageStyled src={'korea_contents_agency_white_logo.png'} />}*/}
        </div>
      </div>
      <div className={`mt-[8px] flex flex-col items-center sm:items-start md:items-start`}>
        <TextStyled>투자트렌드세미나 운영사무국</TextStyled>
        <TextStyled>E. contact@investtrend2024.com</TextStyled>
      </div>
    </InnerContainerStyled>
  </FooterStyled>;
}