import React from 'react';
import { TitleText, InnerContainer } from '../../commonStyled';
import { InvitationStyled, InvitationSummaryStyled, SummaryTextStyled } from '../InvitationStyled';

export default function Ai({ language }) {
  return (<InvitationStyled>
    <InnerContainer>
      <TitleText field='ai'>INVITATION</TitleText>
      <InvitationSummaryStyled>
        <SummaryTextStyled>
          {language === 'en' ? `Warm Greetings from Invest Seoul!\nIt is our great pleasure to invite you to the upcoming “Trend & Investment Seminar 2024” scheduled to be held on August 26th, 2024\n\nThis event includes seminars on overall insight for Korean start ups, MENA region’s AI market, successful entry into MENA, and How to enter the MENA market.\n\nMoreover, the event provides IR pitching session between Domestic AI companies and MENA VCs, and there also will be a separate 1:1 meeting and networking session for the participating companies and VCs.\n\nThis will be a great opportunity for both key stakeholders in the global capital market to better understand Korean companies and Seoul and further expand their business.\n\n` : `안녕하세요,\n서울시 글로벌 자본 유치 전담기관인 인베스트 서울과 서울 AI 허브에서 공동으로 개최하는 AI 투자 트렌드 세미나 2024에 귀사를 초청합니다\n\n금번 세미나에서는 국내 스타트업 및 AI 시장에 대한 전반적인 인사이트와 중동 진출 성공기, 진출 방법에 대한 강연을 진행할 예정입니다.\n\n또한, 국내 AI 기업과 중동 VC 간의 실시간 IR Pitching 현장도 확인하실 수 있고 참가기업, VC 분들과의 1:1미팅 및 네트워킹 시간도 따로 있으니 꼭 참석하셔서 자리를 빛내주시기 바랍니다.\n\n`}
          <b>{language === 'en' ? 'Please grace this occasion with your presence. We look forward to meeting you!' : `귀하의 참석을 기다리며, 함께해 주시기를 바랍니다.\n감사합니다.`}</b>
        </SummaryTextStyled>
      </InvitationSummaryStyled>
    </InnerContainer>
  </InvitationStyled>);
}