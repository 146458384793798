import tw from 'twin.macro';
import styled from '@emotion/styled';

export const TableHeadStyled = tw.div`
  w-full
  flex
  items-center
  h-[32px]
  rounded-t-[8px]
  bg-white
`;

export const TableHeadItemStyled = tw.div`
  flex
  flex-1
  items-center
  justify-center
`;

export const TableHead3ItemStyled = tw.div`
  flex
  flex-[3_3_0%]
  items-center
  justify-center
`;

export const TableHeadTextStyled = tw.span`
  font-bold
  leading-[16px]
  text-[#0B3740]
  text-body1
  sm:text-caption2
  md:text-caption1
  whitespace-pre-wrap
`;

export const RowItemStyled = tw.div`
  min-h-[84px]
  py-[20px]
  flex
  flex-1
  items-center
  justify-center
`;

export const Row3ItemStyled = tw.div`
  min-h-[84px]
  px-[12px]
  py-[20px]
  flex
  flex-[3_3_0%]
  items-center
  justify-center
`;

export const RowItemTextStyled = tw.span`
  font-bold
  leading-[18px]
  text-white
  text-body1
  text-center
  sm:text-caption2
  md:text-caption1
  whitespace-pre-wrap
`;