import './App.css';
import { Footer, Invitation, Main, Overview, Navigation, Registration, Tab } from './components';
import { BrowserRouter } from 'react-router-dom';
import { useState } from 'react';

function App() {
  const [language, setLanguage] = useState('en');
  return (<BrowserRouter>
    <div className='App'>
      <Main>
        <div
          className={`absolute top-[30px] right-[40px] sm:top-[12px] sm:right-[12px] md:top-[20px] md:right-[20px] flex items-center gap-[8px]`}>
            <span
              className={`text-heading1 sm:text-body1 md:text-heading3 font-bold cursor-pointer ${language === 'en' ? 'text-red-500' : 'text-white'}`}
              onClick={() => setLanguage('en')}>EN</span>
          <span className={`text-white text-heading1 sm:text-body1 md:text-heading3 font-bold`}>|</span>
          <span
            className={`text-heading1 sm:text-body1 md:text-heading3 font-bold cursor-pointer ${language === 'kr' ? 'text-red-500' : 'text-white'}`}
            onClick={() => setLanguage('kr')}>KR</span>
        </div>
      </Main>
      {/*<Tab />*/}
      <Invitation language={language} />
      <Overview language={language} />
      <Registration language={language} />
      <Navigation language={language} />
      <Footer />
    </div>
  </BrowserRouter>);
}

export default App;
