import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { InnerContainer, TitleText } from '../commonStyled';
import { OverviewStyled, ScheduleGraphStyled } from './OverviewStyled';
import Ai from './Ai';
import SaaS from './SaaS';

export default function Overview({ language }) {
  const [searchParams, setSearchParams] = useSearchParams();

  // if (searchParams.size === 0) {
  //   return <React.Fragment />;
  // } else {
  //   return <OverviewStyled field={searchParams.get('field')}>
  //     <InnerContainer>
  //       <TitleText className={`!text-white`}>OVERVIEW</TitleText>
  //       <ScheduleGraphStyled>
  //         {(() => {
  //           switch (searchParams.get('field')){
  //             case 'saas':
  //               return <SaaS language={language}/>
  //             case  'ai':
  //             default:
  //               return <Ai language={language}/>
  //           }
  //         })()}
  //       </ScheduleGraphStyled>
  //     </InnerContainer>
  //   </OverviewStyled>;
  // }
  return <OverviewStyled field={'ai'}>
    <InnerContainer>
      <TitleText className={`!text-white`}>OVERVIEW</TitleText>
      <ScheduleGraphStyled>
        <Ai language={language} />
      </ScheduleGraphStyled>
    </InnerContainer>
  </OverviewStyled>;
}