import tw from 'twin.macro'

export const InvitationStyled = tw.article`
  w-full
  py-[40px]
  flex
  items-center
  justify-center
`

export const InvitationSummaryStyled = tw.div`
  w-full
  flex
  items-start
  justify-start
  gap-[16px]
`

export const SummaryTextStyled = tw.span`
  text-heading4
  leading-[24px]
  break-words
  whitespace-pre-wrap
  sm:text-caption1
  md:text-body2
`